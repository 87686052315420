import React from "react";
import { graphql } from "gatsby";
import Container from "../../components/container";
import GraphQLErrorList from "../../components/graphql-error-list";
import Layout from "../../containers/layout";
import SEO from "../../components/seo";
import Intro from "../../components/contact-mentorship";

export const query = graphql`
  query MentorshipInquiriesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
    }
    mentorship: sanityContact {
      _rawAddressColumn1
      _rawAddressColumn2
      _rawAddressColumn3
      mentorshipProgramHeadline
      _rawMentorshipProgramCopyPortable
      mentorshipProgramQuestions
      formSuccess
      formError
    }
  }
`;

const MentorshipPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const mentorship = (data || {}).mentorship;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="Mentorship Program" description={site.description} keywords={site.keywords} />
      <Container>
        <Intro {...mentorship} />
      </Container>
    </Layout>
  );
};

export default MentorshipPage;
