import React, { Component } from 'react';
import PortableText from '@sanity/block-content-to-react';
import validator from 'validator';
import serializers from './serializers';
import AddressBlock from './contact-addresses';
import theme from '../styles/theme.module.scss';
import forms from '../styles/forms.module.scss';
import styles from './contact-intro.module.scss';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

// function is_url(str) {
//   let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
//   if (regexp.test(str)) {
//     return true;
//   } else {
//     return false;
//   }
// }

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { feedbackMsg: null, isValid: true };
    this.domRef = React.createRef();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUrlChange = e => {
    let isValid = validator.isURL(e.target.value);
    this.setState({ [e.target.name]: e.target.value, isValid });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.state.isValid) return false;

    const form = e.target;
    fetch('https://hooks.zapier.com/hooks/catch/615425/b4yb82d', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({
          feedbackMsg: this.props.formSuccess,
        });
        this.domRef.current.reset();
      })
      .catch(error => {
        this.setState({
          feedbackMsg: this.props.formError,
        });
      });
  };

  render() {
    const questions = this.props.mentorshipProgramQuestions;
    return (
      <div className={`${forms.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT6} ${theme.pB3}`}>
        <p className={`${theme.huge} ${theme.gds1} ${theme.gde9} ${theme.gmmain} ${theme.mB3}`}>
          {this.props.mentorshipProgramHeadline}
        </p>
        <div
          className={`${theme.gdfull} ${theme.gmfull} ${theme.gdcont} ${theme.gmcont} ${theme.borderTop} ${theme.pT7}`}>
          <h1
            className={`${forms.title} ${theme.h2_founders} ${theme.gdmain} ${theme.gmmain} ${theme.mB7}`}>
            Mentorship Program
          </h1>
          <PortableText
            className={`${theme.portableText} ${theme.h3_tiempos} ${theme.gds1} ${theme.gde7} ${theme.mB1} ${theme.gmmain}`}
            renderContainerOnSingleChild={true}
            blocks={this.props._rawMentorshipProgramCopyPortable}
            serializers={serializers}
          />

          <form
            ref={this.domRef}
            className={`${forms.form} ${theme.gds3} ${theme.gde10} ${theme.gmmain} ${theme.fdcont} ${theme.fmcont}`}
            name='mentorshipProgram'
            method='post'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            onSubmit={this.handleSubmit}>
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type='hidden' name='form-name' value='mentorshipProgram' />
            <div hidden>
              <label>
                Don’t fill this out: <input name='bot-field' onChange={this.handleChange} />
              </label>
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd4} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='companyname'>
                Company Name*
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='companyname'
                name='companyname'
                required
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd4} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='companyurl'>
                Company Url*
              </label>
              <input
                className={`${forms.field}`}
                id='companyurl'
                name='companyurl'
                required
                onChange={this.handleUrlChange}
                style={{ borderColor: this.state.isValid ? 'black' : 'red' }}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd2} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='industry'>
                Industry
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='industry'
                name='industry'
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd2} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='employees'>
                Number of employees
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='employees'
                name='employees'
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd2} ${theme.fm2}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='state'>
                State*
              </label>
              <div className={`${forms.selectWrap}`}>
                <select
                  className={`${forms.field}`}
                  id='state'
                  name='state'
                  required
                  onChange={this.handleChange}>
                  <option selected disabled>
                    Select One
                  </option>
                  <option value='Alabama'>Alabama</option>
                  <option value='Alaska'>Alaska</option>
                  <option value='Arizona'>Arizona</option>
                  <option value='Arkansas'>Arkansas</option>
                  <option value='California'>California</option>
                  <option value='Colorado'>Colorado</option>
                  <option value='Connecticut'>Connecticut</option>
                  <option value='Delaware'>Delaware</option>
                  <option value='District of Columbia'>District of Columbia</option>
                  <option value='Florida'>Florida</option>
                  <option value='Georgia'>Georgia</option>
                  <option value='Guam'>Guam</option>
                  <option value='Hawaii'>Hawaii</option>
                  <option value='Idaho'>Idaho</option>
                  <option value='Illinois'>Illinois</option>
                  <option value='Indiana'>Indiana</option>
                  <option value='Iowa'>Iowa</option>
                  <option value='Kansas'>Kansas</option>
                  <option value='Kentucky'>Kentucky</option>
                  <option value='Louisiana'>Louisiana</option>
                  <option value='Maine'>Maine</option>
                  <option value='Maryland'>Maryland</option>
                  <option value='Massachusetts'>Massachusetts</option>
                  <option value='Michigan'>Michigan</option>
                  <option value='Minnesota'>Minnesota</option>
                  <option value='Mississippi'>Mississippi</option>
                  <option value='Missouri'>Missouri</option>
                  <option value='Montana'>Montana</option>
                  <option value='Nebraska'>Nebraska</option>
                  <option value='Nevada'>Nevada</option>
                  <option value='New Hampshire'>New Hampshire</option>
                  <option value='New Jersey'>New Jersey</option>
                  <option value='New Mexico'>New Mexico</option>
                  <option value='New York'>New York</option>
                  <option value='North Carolina'>North Carolina</option>
                  <option value='North Dakota'>North Dakota</option>
                  <option value='Northern Marianas Islands'>Northern Marianas Islands</option>
                  <option value='Ohio'>Ohio</option>
                  <option value='Oklahoma'>Oklahoma</option>
                  <option value='Oregon'>Oregon</option>
                  <option value='Pennsylvania'>Pennsylvania</option>
                  <option value='Puerto Rico'>Puerto Rico</option>
                  <option value='Rhode Island'>Rhode Island</option>
                  <option value='South Carolina'>South Carolina</option>
                  <option value='South Dakota'>South Dakota</option>
                  <option value='Tennessee'>Tennessee</option>
                  <option value='Texas'>Texas</option>
                  <option value='Utah'>Utah</option>
                  <option value='Vermont'>Vermont</option>
                  <option value='Virginia'>Virginia</option>
                  <option value='Virgin Islands'>Virgin Islands</option>
                  <option value='Washington'>Washington</option>
                  <option value='West Virginia'>West Virginia</option>
                  <option value='Wisconsin'>Wisconsin</option>
                  <option value='Wyoming'>Wyoming</option>
                </select>
              </div>
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd2} ${theme.fm2}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='zipcode'>
                Zip Code*
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='zipcode'
                name='zipcode'
                required
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd4} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='name'>
                Contact Name*
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='name'
                name='name'
                required
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB7} ${theme.fd4} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='email'>
                Email address*
              </label>
              <input
                className={`${forms.field}`}
                type='email'
                id='email'
                name='email'
                required
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB8} ${theme.fd2} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='contactphone'>
                Contact Phone*
              </label>
              <input
                className={`${forms.field}`}
                type='tel'
                id='contactphone'
                name='contactphone'
                required
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB8} ${theme.fd2} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='linkedin'>
                Linkedin
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='linkedin'
                name='linkedin'
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB8} ${theme.fd2} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='instagram'>
                Instagram
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='instagram'
                name='instagram'
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB8} ${theme.fd2} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB12}`} htmlFor='twitter'>
                Twitter
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='twitter'
                name='twitter'
                onChange={this.handleChange}
              />
            </div>
            <p>* indicates required fields</p>

            <div className={`${theme.mB4}`}>
              {questions.map((question, index) => {
                return (
                  <div
                    className={`${forms.formGroup} ${theme.mT1} ${theme.fd8} ${theme.fm4}`}
                    key={index}>
                    <label
                      className={`${theme.h5_founders} ${theme.mB12}`}
                      htmlFor={`question-${string_to_slug(question)}`}>
                      {question}
                    </label>
                    <textarea
                      className={`${forms.field} ${forms.textarea}`}
                      type='textarea'
                      name={string_to_slug(question)}
                      id={`question-${string_to_slug(question)}`}
                      onChange={this.handleChange}
                    />
                  </div>
                );
              })}
            </div>

            {this.state.feedbackMsg && (
              <div
                className={`${forms.message} ${theme.gds3} ${theme.gde10} ${theme.gmmain} ${theme.center}`}>
                <p>{this.state.feedbackMsg}</p>
              </div>
            )}
            <div
              className={`${forms.formGroup} ${forms.submitGroup} ${theme.mB1} ${theme.mT4} ${theme.fd8} ${theme.fdcont} ${theme.fdcenter}`}>
              <input
                className={`${forms.submit} ${theme.h3_founders} ${theme.fd4} ${theme.fm4}`}
                type='submit'
                value='Submit'
                tabIndex='0'
              />
            </div>
          </form>
        </div>
        <div
          className={`${styles.root} ${theme.gdfull} ${theme.gdcont} ${theme.gmfull} ${theme.gmcont} ${theme.pT3} ${theme.borderTop}`}>
          <AddressBlock {...this.props} />
        </div>
      </div>
    );
  }
}
